import { Controller } from "stimulus";
import Request from "../utils/request";

export default class extends Controller {
  static targets = ['hint', 'input']

  fetch(event) {
    event.preventDefault();

    const { target, target: { href } } = event

    target.innerText = "Finding..."
    Request.fetch(href, {
      headers: {
        "Content-Type": "text/html",
        Accept: "text/html"
      }
    })
      .then(response => response.text())
      .then(html => {
        this.hintTarget.innerHTML = html
      });
  }

  autofill(event) {
    event.preventDefault()

    const username = event.target.dataset.id

    this.inputTarget.value = username
    this.hintTarget.remove()
  }
}
