import { fetch as fetchPolyfill } from 'whatwg-fetch'

const getCsrfToken = () => {
  const token = document.head.querySelector("[name='csrf-token']").getAttribute('content')

  return token
}

const getHeaders = () => ({
  'X-CSRF-Token': getCsrfToken(),
  'X-Requested-With': 'XMLHttpRequest',
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8'
})

const buildOptions = (init = {}) => {
  const options = init
  options.headers = { ...getHeaders(), ...init.headers }
  options.credentials = 'same-origin'
  options.redirect = 'error'
  return options
}

const fetch = (url, init) => {
  const options = buildOptions(init)
  return fetchPolyfill(url, options)
}

export default {
  fetch
}
